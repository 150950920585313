import { redirectToAuth } from "supertokens-auth-react";
import { attemptRefreshingSession } from "supertokens-web-js/recipe/session";
import Session from "supertokens-auth-react/recipe/session";
import SuperTokens from "supertokens-auth-react";

export default async function fetchJson<JSON = unknown>(
  input: RequestInfo,
  init?: RequestInit,
  retry = false,
): Promise<any> {
  let req = input;
  if (typeof req === "string") {
    if (process.env.NODE_ENV === "development") {
      req = `https://wvemedia.com/backend/${req}`;
    } else {
      req = `/backend/${req}`;
    }
  }
  const options = {
    ...init,
    headers: {
      ...init?.headers,
      "Content-Type": "application/json",
    } as any,
  };
  const response = await fetch(req, options);

  // if the server replies, there's always some data in json
  // if there's a network error, it will throw at the previous line

  const data = await response.json();

  // response.ok is true when res.status is 2xx
  // https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
  if (response.ok) {
    if (data.data) {
      //wait a second
      // await new Promise((resolve) => setTimeout(resolve, 100000));
      return data.data;
    }
    return data;
  }

  if (response.status === 418) {
    throw new Error("access-code");
  }

  if (response.status === 402) {
    throw new Error("payment-required");
  }

  if (response.status === 401) {
    console.log("401", retry);
    // if (!retry) {
    //   try {
    //     if (data?.error?.type === "TRY_REFRESH_TOKEN") {
    //       await fetch("/backend/supertokens/session/refresh", {
    //         method: "POST",
    //       });
    //       return fetchJson(input, init, true);
    //     }
    //     // const refresh = await Session.attemptRefreshingSession();
    //     // if (refresh) {
    //     //   console.log("success", refresh);
    //     //   return fetchJson(input, init, true);
    //     // } else {
    //     //   return redirectToAuth({ redirectBack: true });
    //     // }
    //   } catch (e) {
    return redirectToAuth({ redirectBack: true });
    // }
    // }

    if (!window.location.pathname.includes("/auth"))
      redirectToAuth({ redirectBack: true });
    return;
    throw new FetchError({
      message: response.statusText,
      response,
      data,
    });
  }

  if (response.status === 403) {
    window.history.replaceState({}, "", "/unauthorized");
  }
  if (response.status >= 500) {
    return null;
  }

  console.log(
    "ERRRRR",
    new FetchError({
      message: response.statusText,
      response,
      data,
    }),
  );
  return data;
}

export class FetchError extends Error {
  response: Response;
  data: {
    message: string;
  };
  constructor({
    message,
    response,
    data,
  }: {
    message: string;
    response: Response;
    data: {
      message: string;
    };
  }) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(message);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }

    this.name = "FetchError";
    this.response = response;
    this.data = data ?? { message: message };
  }
}
